<template>
  <section id="content" ref="companiesContent">
    <PageTitle :title="$t('admin_companies.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row generate-success">
        <div class="col-lg-12">
          
          <div class="ibox">
            <div class="ibox-content">
                <span class="text-muted small float-right">Last modification: <i class="fa fa-clock-o"></i> 2:10 pm - 12.06.2014</span>
                <h2>Clients</h2>
                <p>
                    All clients need to be verified before you can send email and set a project.
                </p>
                
                <div class="clients-list">
                <span class="float-right small text-muted">1406 Elements</span>

                <b-tabs>
                  <b-tab active>
                    <template slot="title">
                      <i class="fa fa-user"></i> Contacts
                    </template>

                    <div class="input-group">
                      <input type="text" placeholder="Search contact" class="input form-control">
                      <span class="input-group-append">
                        <button type="button" class="btn btn btn-primary"> <i class="fa fa-search"></i> Search</button>
                      </span>
                      <button class="btn btn-primary" @click="showCreateContactModal()">Create contact</button>
                    </div>

                    <b-table 
                        hover striped responsive
                        :items="listContactsProvider"
                        :fields="listContactsFields"
                        ref="listContacts">
                      <template v-slot:cell(select)="row">
                        <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listContactsSelected"></p-check>
                      </template>
                      <template v-slot:cell(options)="row">
                        <a href="javascript:void(0)" @click="showUpdateContactModal(row.item)">Update</a>
                        /
                        <a href="javascript:void(0)" @click="confirmRemoveContact(row.item)">Delete</a>
                      </template>
                    </b-table>

                  </b-tab>
                  <b-tab>
                    <template slot="title">
                      <i class="fa fa-briefcase"></i> Companies
                    </template>

                    <ListCompanies></ListCompanies>

                  </b-tab>
                </b-tabs>  

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<style scoped>
  .clients-list thead{
    display:none;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { languagesTypes } from '@fwk-client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import Messages from '../../panels/Messages.vue';
import ListCompanies from '../../panels/admin/companies/ListCompanies.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {
    PageTitle,
    ListCompanies
  }
})
export default class Companies extends mixins(GenericPage) {

  contactToRemove:any = {};
  contactToUpdate:any = {};

  listContactsSelected = [];

  listContactsFields = [
      {
        key: "select",
        label: ""
      },
      {
        key: "name"
      },
      {
        key: "company"
      },
      {
        key: "contact"
      },
      {
        key: "options"
      }
    ];

  confirmRemoveContact(contact:any) {
    this.contactToRemove = contact;
    // @ts-ignore
    this.$refs.removeContactModal.show()
  }

  showUpdateContactModal(contact:any) {
    this.contactToUpdate = contact;
    // @ts-ignore
    this.$refs.updateContactModal.show()
  }

  listContactsProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }
    return [
      {
        id: 1,
        name: "Anthony Jackson",
        company: "Company 1",
        contact: "anthony@jackson.fr"
      },
      {
        id: 2,
        name: "Anthony Jackson",
        company: "Company 1",
        contact: "anthony@jackson.fr"
      },
      {
        id: 3,
        name: "Anthony Jackson",
        company: "Company 1",
        contact: "anthony@jackson.fr"
      },
      {
        id: 4,
        name: "Anthony Jackson",
        company: "Company 1",
        contact: "anthony@jackson.fr"
      }
    ];
  }
}
</script>